import React, { useState } from 'react'
import {
    Button,
} from "@shopify/polaris"
import update from 'immutability-helper'
import ProductOffer from "./productOffer"
function OfferProductsSetting(props) {
  const {
    host,
    shop,
    state,
    setState,
    productPickerShow,
    error,
    productEdit,
    setProductEdit,
    currency,
    currencySymbol = "$",
    token,
    checkoutOfferType,
  } = props
  const [downsellEdit, setDownsellEdit] = useState(state.product.length > 1 && state.product[1].shownWhenFirst ? state.product[1].shownWhenFirst : null)
  const addDownsellOffer = () => {
    setDownsellEdit("denied")
    setState(
      update(state, {
        product: {
          $push: [
            {
              shownWhenFirst: "denied",
              expiration: 15,
              products: [
                {
                  discount: { sym: currencySymbol, value: 0 },
                },
              ],
              productsPerRow: 1,
              productNumber: 1,
            },
          ],
        },
      })
    )
  }
  return (
    <div style={{ marginTop: "1.6rem", marginBottom: "1.6rem" }}>
      <div>
        {state.product.map((offer, index) => {
          return (
            <ProductOffer
              key={index}
              offer={offer}
              index={index}
              host={host}
              shop={shop}
              token={token}
              state={state}
              setState={setState}
              error={error}
              productEdit={productEdit}
              setProductEdit={setProductEdit}
              productPickerShow={productPickerShow}
              currency={currency}
              currencySymbol={currencySymbol}
              checkoutOfferType={checkoutOfferType}
              downsellEdit={downsellEdit}
              setDownsellEdit={setDownsellEdit}
            />
          )
        })}
        {state.product.length < 2 ? (
          // we'll show the button when the downsell product is deleted
          <div style={{ marginTop: "10px" }}>
            <Button fullWidth onClick={addDownsellOffer}>
              + {state.product.length > 0 ? "Add a downsell offer" : false}
            </Button>
          </div>
        ) : (
          false
        )}
      </div>
    </div>
  )
}

export default OfferProductsSetting;