import React, {
    Fragment,
    useState,
  } from 'react'
  import update from 'immutability-helper'
  import equal from 'deep-equal'
  import { 
    Badge,
    Button,
    ButtonGroup,
    Card,
    Form,
    Page,
    Subheading,
  } from "@shopify/polaris"
  import { withFirebase } from '../../../providers/firebase'
  import styled from 'styled-components'
  import { navigate } from "gatsby"
  import PostPurchasePreview from './postpurchase/postPurchasePreview'
  import './create.css'
  import Constants from '../../../helpers/constants'
  import createRule from '../../../helpers/createRule'
  import { updateRule, deleteRule } from '../../../helpers/updateRule'
  import SmartRules from './smartRules'
  import ProductTab from './postpurchase/productTab';
  import { doc } from 'firebase/firestore';
  import { useDocumentData } from 'react-firebase-hooks/firestore';
  import getSymbolFromCurrency from 'currency-symbol-map'
  import CreateOfferSteps from './createOfferSteps';
  import OfferStatusButton from './common/offerStatusButton/offerStatusButton';
  import ShopBrain from './common/instructions/shopBrain/shopBrain';
  import formatLastSavedDate from '../../../helpers/formatLastSavedDate'
  import './common.css';
  // Source: https://stackoverflow.com/a/44134328
  
  const PostPurchaseWrapper = styled.div`
    display: flex;
    flex-direction: row;
  `
  function Create(props) {
    const { token, shop, location, firebase, host } = props
    const existingOffer = location && location.state && location.state.rule
    const rules = location && location.state && location.state.rules
    const isEditing = !!existingOffer
    const isCreating = !isEditing
  
    const initialEditingState = {
      id: existingOffer && existingOffer.id || null, // always null if not created
      offerType: existingOffer && existingOffer.offerType || 'Post Purchase',
      name: existingOffer && existingOffer.name || '',
      offerStarting: existingOffer && existingOffer.offerStarting || '',
      offerEnding: existingOffer && existingOffer.offerEnding || '',
      product: existingOffer && existingOffer.product || [
        { 
          expiration: 15,
          products: [
            {
              discount: { sym: '$', value: 0 }
            }
          ],
          productsPerRow: 1,
        }
      ],
      discount: existingOffer && existingOffer.discount || {sym:'$',value:0},
      enabled: existingOffer && existingOffer.enabled || false,
      triggers: existingOffer && existingOffer.triggers || {
        conditions:{
            all:[
                {
                    any: [
                        {fact:'cartTotal',operator: 'greaterThanString',value: '0.00'}
                    ]
                }
                ]
        },
        event: { 
            type: 'foundout',
            params: {
              message: 'rule has found out!'
              }
          }
      },
      shopBrain: existingOffer ? existingOffer?.shopBrain ? existingOffer?.shopBrain?.inclusion ? existingOffer?.shopBrain : {inclusion:[{setting: 'tags',value: null}],exclusion: [{setting: 'tags',value: null}]} : null : null,
      aiConditions: existingOffer ? existingOffer.aiConditions : {
        customerTags: [],
        products: []
      },
      variantsTriggers: existingOffer && existingOffer.variantsTriggers || {
        if:{
          all:[
            {fact:'cartProducts',operator: 'hasAny',value: null},
            {fact:'cartProducts',operator: 'Variant', value: null}
          ]
        },
        then: {
          all:[
            {fact:'upsellVariant',operator: 'Variant',value: null}
          ]
        },
        swap: false,
        type: 'manual'
      },
      isExcludeProduct: existingOffer?.isExcludeProduct ? existingOffer.isExcludeProduct : false,
    }
    const [state, setState] = useState(initialEditingState)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [productPickerShow, setProductPickerShow] = useState('product')
    const [productEdit, setProductEdit] = useState(
      isCreating ? [0, 0] : [-1, -1]
    )
    const [error, setError] = useState(null)
    const [offerType, setOfferType] = useState(() => {
      if (state?.variantsTriggers?.type) {
        return state?.variantsTriggers?.type;
      } else {
        return state.shopBrain ? 'manual' : 'ai';
      }
    });
    const shopDoc = doc(firebase.firestore, 'shops', shop);
    const [shopData, shopDataLoading, shopDataError] = useDocumentData(
      shopDoc,
      {
        transform: (snap) => snap.shopData
      }
    );

    let currentState = Object.assign({},state);
    let initialStateForCheck = Object.assign({},initialEditingState);
    delete currentState.enabled;
    delete initialStateForCheck.enabled;
    const hasUnsavedChanges = isEditing && !equal(currentState, initialStateForCheck)

    const handleRuleDelete = async () => {
      await deleteRule(state.id, token, shop, firebase);
  
      navigate('/app/offers', {
        state: {
          ...location.state,
          tabIndex: Constants.TAB_INDEX.OFFER_LIST
        },
        replace: true,
      })
    }
    const handleFormSubmit = async (type) => {
  
      if (isSubmitting) {
        return
      }
      if(state.product === null && !state?.shopBrain){
        setError({product: "Please select a product"})
        return
      }else{
        if(error != null){
          setError(null)
        }
      }
      setIsSubmitting(true)
  
      try {
        if (isEditing) {
          // TODO
          console.log('update rule.')
          await updateRule({
            ...state /* all rule data */,
            triggers: state.triggers ? (state.triggers.conditions.all.length > 0 ? state.triggers: null): null,
            type: state.discount > 0 ? 'discount' : 'regular', // TODO handle product_discount eventually
          }, token, shop, firebase);
  
          navigate(
            `/app/offers/createPostPurchase`,
            {
              state: {
                rule: {...state, updatedAt: new Date().toISOString()}
              },
              replace: true,
            }
          )
        } else {
                 
          const result = await createRule({
            ...state /* all rule data */,
            triggers: state.triggers ? (state.triggers.conditions.all.length > 0 ? state.triggers: null): null,
            enabled: "save" === type ? false : true,
          }, token, shop, host)
          if(result && result.data && result.data.rules.filter( rule => "Post Purchase" === rule.offerType ).length > 1){
            navigate(
              `/app/offers/postPurchaseOfferItem`,
              {
                state: {
                  rule: result.data.rules.find(rule => rule.id === result.data.id),
                },
                replace: true,
              }
            )
          }else{
          
            navigate(
              `/app/offers/createCEOfferSuccess`,
              {
                replace: true,
              }
            )
          }
          console.log('result from creating rule: ', result)
        }
  
      }
      catch (e) {
        console.log('Error creating rule: ', e)
      }
  
      setIsSubmitting(false)
    }
  
    const addSmartRule =  () => {
      const conditions = {
        conditions:{
            all:[
                {
                    any: [
                        {fact:'cartTotal',operator: 'greaterThanString',value: '0.00'}
                    ]
                }
                ]
        },
        event: { 
            type: 'foundout',
            params: {
              message: 'rule has found out!'
            }
        }
    }
      setState(update(state, { triggers: { $set: conditions }}))
    }
 
    const locale = shopData && shopData.primary_locale || "en-US";
    const currency = (shopData && shopData.currency) ? shopData.currency : 'USD';
    const currencySymbol = getSymbolFromCurrency(currency) ? getSymbolFromCurrency(currency) : '$';
    return (
      <Page
        fullWidth
        //primaryAction={{content: 'Save'}}
      >
        {isEditing ?
          <Fragment>
            <div style={{padding: '10px 0', width: '550px', paddingRight: '3rem'}}>
              <div className="Polaris-Page-Header__Row">
              <div className="Polaris-Page-Header__BreadcrumbWrapper" onClick={() => {
                            navigate('/app/offers', 
                            {
                              state: location.state,
                              replace: true,
                            })
                          }}>
                <nav role="navigation"><a data-polaris-unstyled="true" className="Polaris-Breadcrumbs__Breadcrumb"><span className="Polaris-Breadcrumbs__Icon"><span className="Polaris-Icon"><span className="Polaris-VisuallyHidden"></span><svg viewBox="0 0 20 20" className="Polaris-Icon__Svg" focusable="false" aria-hidden="true"><path d="M17 9H5.414l3.293-3.293a.999.999 0 1 0-1.414-1.414l-5 5a.999.999 0 0 0 0 1.414l5 5a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414L5.414 11H17a1 1 0 1 0 0-2z"></path></svg></span></span><span className="Polaris-VisuallyHidden"></span></a></nav>
              </div>
                <div className="Polaris-Page-Header__TitleWrapper">
                  <div className='OfferHeaderContainer'>
                    <div className="Polaris-Header-Title__TitleAndSubtitleWrapper" style={{width: '100%'}}>
                      <div className='OfferHeaderContainer'>
                        <div>
                          <h1 className="Polaris-Header-Title">
                            {initialEditingState.name}
                          </h1>
                          <span>{formatLastSavedDate(existingOffer?.updatedAt)}</span>
                        </div>
                        <div style={{
                          display: 'flex',
                          marginLeft: 10,
                          alignItems: 'center'
                        }}>
                          <div
                            className='OfferStatusMark'
                            style={{backgroundColor: state.enabled ? "#008060" : "#717171"}}
                          />
                          <OfferStatusButton
                            token={token}
                            shop={shop}
                            firebase={firebase}
                            state={state}
                            setState={setState}
                          />
                        </div>
                      </div>
                      {
                        hasUnsavedChanges &&
                        <Fragment>
                          <Badge>Unsaved Changes</Badge>
                          { false && <div style={{
                            float: 'right',
                            marginRight: '8em',
                          }}>
                            <Button
                              onClick={handleFormSubmit}
                              primary
                              loading={isSubmitting}>
                              Save
                            </Button>
                          </div>
                          }
                        </Fragment>
                      }
                    </div>
                  </div>
              </div>
            </div>
            </div>
          </Fragment> : 
          <div style={{padding: '10px 0'}}>
            <h1 className="Polaris-Header-Title" style={{ display: 'inline'}}>Create New Offer</h1>
          </div>
        }
        <div>
        <PostPurchaseWrapper>
          <div>
          {!existingOffer && (!rules || (rules && Array.isArray(rules) && rules.filter( rule => "Post Purchase" === rule.offerType ).length === 0)) ?
          <div style={{margin: '20px 0 10px -25px'}}>
            <CreateOfferSteps step={2}/>
          </div>
          :
          false
          } 
          <div style={{ paddingRight: '3rem', marginBottom: 50, width: '550px'}}>
            <Form 
                  disabled={
                    !state.product || 
                    !state.name ||
                    !state.title
                  }
                  onSubmit={handleFormSubmit}
            >

              <ProductTab host={host} shop={shop} token={token} shopData={shopData} state={state} setState={setState} productPickerShow={productPickerShow} error={error} currency={currency} currencySymbol={currencySymbol} productEdit={productEdit} setProductEdit={setProductEdit} checkoutOfferType={offerType} setCheckoutOfferType={setOfferType} existingOffer={existingOffer}/>

              {
                state?.shopBrain &&
                  <ShopBrain host={host} shop={shop} token={token} state={state} setState={setState} productPickerShow={productPickerShow} setProductPickerShow={setProductPickerShow} checkoutOfferType={offerType} offerType={"pp"}/>
              }
              
              <Card sectioned>
                <div style={{paddingBottom: '10px'}}>
                  <Subheading>Smart Rules</Subheading>
                </div>
                <SmartRules host={host} shop={shop} token={token} addSmartRule={addSmartRule} state={state} setState={setState} productPickerShow={productPickerShow} setProductPickerShow={setProductPickerShow} currencySymbol={currencySymbol} type={"pp"}/>
              </Card>
              <div 
                      style={{ 
                        marginTop: 50,
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'row-reverse',
                        justifyContent: 'flex-start',
                      }}
                    >
                      <div>
                          {
                            isCreating &&
                              <ButtonGroup>
                                <Button 
                                    onClick={ () => handleFormSubmit("save")} 
                                    primary 
                                    loading={isSubmitting}>
                                    Save
                                  </Button>
                                <Button submit primary loading={isSubmitting}>Save and publish</Button>
                              </ButtonGroup>
                          }
                          {
                            isEditing &&
                              <ButtonGroup>
                                <Button 
                                  destructive
                                  outline
                                  onClick={ () => { handleRuleDelete() } }>
                                  Delete
                                </Button>
                                <Button 
                                  submit 
                                  primary 
                                  loading={isSubmitting}>
                                  Save
                                </Button>
                              </ButtonGroup>
                          }
                      </div>
                      <div style={{ marginRight: 30 }}>
                        { isCreating ?
                          <Button 
                          plain
                          destructive={hasUnsavedChanges || isCreating}
                          onClick={() => {
                            navigate('/app/offers', {
                              state: {
                                ...location.state 
                              },
                              replace: true,
                            })
                          }}
                        >
                            {
                            hasUnsavedChanges ? 
                            'Discard Changes' : 
                              (isCreating ? 'Discard' : 'Back')
                          }
                        </Button>
                        :
                        <Button 
                          plain
                          destructive={hasUnsavedChanges || isCreating}
                          onClick={() => {
                            navigate('/app/offers', 
                            {
                              state: location.state,
                              replace: true,
                            })
                          }}
                          >
                              {
                            hasUnsavedChanges ? 
                            'Discard Changes' : 
                              (isCreating ? 'Discard' : 'Back')
                          }
                        </Button>
                        }
                        
                      </div>
                    </div>
              </Form>
            </div>
          </div>
          <div style={{width: '100%'}}>
                  <div>
                    <PostPurchasePreview 
                      {...state} 
                      {...props}
                      currencySymbol={currencySymbol}
                      locale={locale}
                      currency={currency}
                      productEdit={productEdit}
                      offerType={offerType}
                    />
                  </div>
          </div>
        </PostPurchaseWrapper>
        </div>
      </Page>
    );
  }
  
  export default withFirebase(Create);